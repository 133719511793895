<template>
    <div v-if="service" class="d-block d-lg-flex w-100">
        <div class="service-left d-flex flex-column">
            <div class="title">
                {{service.title}}
            </div>
            <div class="mt-1 service-content" v-html="stripHTML(service.content)"/>
            <div class="mt-1 mt-lg-auto d-flex justify-content-between align-items-end">
                <router-link :to="{ name: 'service', params: {service_id: service.id, is_index: false }}">
                    <rounded-button class="more-button position-relative" padding="5px 15px">
                        <span class="text-left pr-3" style="color: #72A5D8 !important;">
                            Več o tem
                        </span>
                    </rounded-button>
                </router-link>
                <div class="date" style="font-weight: bold;">
                    <span>{{ dayjs(service.date_added).format('DD.MM') }}</span>
                    <span style="font-weight: 400;">{{ dayjs(service.date_added).format('.YYYY') }}</span>
                </div>
            </div>
        </div>
        <div class="image">
            <router-link :to="{ name: 'service', params: {service_id: service.id, is_index: false }}">
                <img class="main-photo" :src="`/api/user/v1/image/${service.main_photo}`"/>
            </router-link>
        </div>
    </div>
</template>

<script>
    import RoundedButton from '@/views/Components/RoundedButton'
    export default {
        components: {RoundedButton},
        props:{
            service: {
                type: Object
            }
        },
        methods: {
            stripHTML(val) {
                if (!val) return ''
                return String(val).replace(/(<([^>]+)>)/gi, ' ').trim()
            }
        }
    }
</script>

<style scoped>
.service-left {
  width: 33%;
  background-color: #72A5D8;
  color: white;
  padding: 1.5rem;
}
.title{
  font-weight: 700;
  font-size: 33px;
  line-height: 41px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  display: -webkit-box;
}
.service-content {
  font-weight: 400;
  font-size: 17px;
  line-height: 21px;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
.main-photo {
  width: 100%;
  height: 360px;
  object-fit: cover;
}
.more-button {
  font-weight: 600;
  font-size: 17px;
  line-height: 21px;
  color: #72A5D8 !important;
  background-color: white !important;
  border-color: white !important;
}
.image {
  width: 66%;
  height: 100%;
}

@media only screen and (max-width: 990px) {
  .service-left {
    width: 100%;
  }
  .image {
    width: 100%;
  }
  .main-photo {
    height: 150px;
  }
  .title {
    -webkit-line-clamp: 1;
  }
  .service-content {
    -webkit-line-clamp: 2;
  }
}
</style>