<template>
    <b-overlay>
        <div class="bcard-background">
            <div class="pt-0 pt-md-5">
                <b-row align-content="center">
                    <b-col xs="12" md="5" class="mt-0 mt-md-5">
                        <div class="d-flex justify-content-center">
                            <div class="text-left font-large-2" style="font-weight: bold;">Dobrodošel<br> etažni lastnik</div>
                        </div>
                        <div class="d-flex justify-content-center">
                            <div class="text-left font-medium-4" >Enostaven in hiter dostop do<br> informacij vašega stanovanja</div>
                        </div>
                        <div class="mt-3 d-flex justify-content-center">
                            <b-button class="button text-center font-medium-2  w-50 px-2 py-1 m-0" @click="$router.push('/o-nas')" style="max-width: 240px;  background: #72a5D8 !important; color: white!important;" pill>Več o tem</b-button>
                        </div>
                    </b-col>
                    <b-col xs="12" md="7">
                        <b-img fluid :src="require('@/assets/images/esoseska/esoseska_homepage_1.png')" />
                    </b-col>
                </b-row>
            </div>

            <div class="d-block d-md-none">
                <b-row>
                    <b-col cols="1"/>
                    <b-col cols="10">
                        <div class="text-left mb-2">
                            <div class="font-large-1" style="font-weight: 700; font-size: 33px; line-height: 41px;">
                                eSoseska ponuja<br> pregled nad:
                            </div>
                        </div>
                        <div class="text-left font-medium-2">vašimi enotami, razdelilniki, skladi, odprtimi<br> postavkami, oglasno desko, dokumenti,<br> eSestanki, obrazci</div>
                    </b-col>
                    <b-col cols="1"/>
                </b-row>
            </div>
            <div class="d-none d-md-block" style="margin-top: -2rem!important;">
                <div class="text-left mb-2" style="margin-top: 3.5rem;">
                    <div style="font-weight: 700; font-size: 33px; line-height: 41px;">
                        eSoseska ponuja pregled nad:
                    </div>
                </div>
                <div style="position: relative;" class="">
                    <hr style="position: absolute; left: 0px; transform: translateX(-67vh); padding-left: 50%; top: 0px; width: 14000px;  height:0px; border: 1px solid black;"/>   
                    <b-row style="padding-top: 10px;" class="font-medium-2">
                        <b-col class="pl-0">
                            <div class="d-flex justify-content-center">
                                <div style="height: 10px!important; width: 11.22px!important; background-color: black; border-radius: 50px; visibility: visible!important;"/>
                            </div>
                            <div class="text-center mt-1">vašimi<br> enotami</div>
                        </b-col>
                        <b-col>
                            <div class="d-flex justify-content-center">
                                <div style="height: 10px!important; width: 11.22px!important; background-color: black; border-radius: 50px; visibility: visible!important;"/>
                            </div>
                            <div class="text-center mt-1">razdelilniki</div>
                        </b-col>
                        <b-col>
                            <div class="d-flex justify-content-center">
                                <div style="height: 10px!important; width: 11.22px!important; background-color: black; border-radius: 50px; visibility: visible!important;"/>
                            </div>
                            <div class="text-center mt-1">skladi</div>
                        </b-col>
                        <b-col>
                            <div class="d-flex justify-content-center">
                                <div style="height: 10px!important; width: 11.22px!important; background-color: black; border-radius: 50px; visibility: visible!important;"/>
                            </div>
                            <div class="text-center mt-1">odprtimi<br> postavkami</div>
                        </b-col>
                        <b-col>
                            <div class="d-flex justify-content-center">
                                <div style="height: 10px!important; width: 11.22px!important; background-color: black; border-radius: 50px; visibility: visible!important;"/>
                            </div>
                            <div class="text-center mt-1">obrazci</div>
                        </b-col>
                        <b-col>
                            <div class="d-flex justify-content-center">
                                <div style="height: 10px!important; width: 11.22px!important; background-color: black; border-radius: 50px; visibility: visible!important;"/>
                            </div>
                            <div class="text-center mt-1">oglasno<br> desko</div>
                        </b-col>
                        <b-col>
                            <div class="d-flex justify-content-center">
                                <div style="height: 10px!important; width: 11.22px!important; background-color: black; border-radius: 50px; visibility: visible!important;"/>
                            </div>
                            <div class="text-center mt-1">dokumenti</div>
                        </b-col>
                        <b-col class="pr-0">
                            <div class="d-flex justify-content-center">
                                <div style="height: 10px!important; width: 11.22px!important; background-color: black; border-radius: 50px; visibility: visible!important;"/>
                            </div>
                            <div class="text-center mt-1">eSestanki</div>
                        </b-col>
                    </b-row>         
                </div>
            </div>

        
            <div style="margin-top: 3.5rem;">
                <div class="d-block d-lg-none">
                    <b-row>
                        <b-col cols="1"/>
                        <b-col cols="10">
                            <div class="text-left font-large-2" style="font-weight: bold; white-space: nowrap;">Registriraj se!</div>
                            <div class="text-left font-medium-5" >Registrirani uporabniki<br> pridobijo dostop do:</div>
                        </b-col>
                        <b-col cols="1"/>
                    </b-row>
                </div>
                <b-row>
                    <b-col lg="2" style="position: relative; overflow: visible;" class="d-none d-lg-block">
                        <div class="mt-3" style="position: absolute; left: 50%; z-index: 10!important;">
                            <div class="text-center font-large-2" style="font-weight: bold; white-space: nowrap;">Registriraj se!</div>
                            <div class="text-center font-medium-5" >Registrirani uporabniki<br> pridobijo dostop do:</div>
                        </div>
                    </b-col>
                    <b-col lg="6" sm="12" class="d-flex justify-content-center">
                        <b-img style="z-index: 0!important;" fluid :src="require('@/assets/images/esoseska/esoseska_homepage_2.png')" />
                    </b-col>
                    <b-col md="4" class="d-none d-lg-block">
                        <div class="text-left" style="margin-top: 6rem;">
                            <div class="d-flex justify-content-left mt-2">
                                <b-img fluid class="" :src="iconCheckMark" />
                                <div class="font-medium-5 ml-2" >Popustov na izdelke v spletni trgovini eSoseska</div>
                            </div>
                            <div class="d-flex justify-content-left mt-2">
                                <b-img fluid class="" :src="iconCheckMark" />
                                <div class=" font-medium-5 ml-2" >pestre ponudbe trga nepremičnin</div>
                            </div>
                            <div class="d-flex justify-content-left mt-2">
                                <b-img fluid class="" :src="iconCheckMark" />
                                <div class="font-medium-5 ml-2" >aktualnih novic iz stanovanjske tematike</div>
                            </div>
                            <div class="d-flex justify-content-left mt-2">
                                <b-img fluid class="" :src="iconCheckMark" />
                                <div class="font-medium-5 ml-2" >ugodnih storitev za vaš dom</div>
                            </div>
                            <div class="d-flex justify-content-left mt-2">
                                <b-img fluid class="" :src="iconCheckMark" />
                                <div class="font-medium-5 ml-2" >spletne tržnice z vašimi oglasi</div>
                            </div>
                            <div class="mt-3">
                                <b-button class="button font-medium-2 text-center px-3 py-1 m-0" style=" background: #72a5D8 !important; color: white!important;" pill @click="$router.push('/registracija');" >Na registracijo</b-button>
                            </div>
                        </div>
                    </b-col>
                </b-row>
                <div class="d-block d-lg-none">
                    <div class="d-flex justify-content-left mt-2 ml-2">
                        <b-img fluid class="" :src="iconCheckMark" />
                        <div class="font-medium-5 ml-2" >Popustov na izdelke v spletni trgovini eSoseska</div>
                    </div>
                    <div class="d-flex justify-content-left mt-2 ml-2">
                        <b-img fluid class="" :src="iconCheckMark" />
                        <div class=" font-medium-5 ml-2" >pestre ponudbe trga nepremičnin</div>
                    </div>
                    <div class="d-flex justify-content-left mt-2 ml-2">
                        <b-img fluid class="" :src="iconCheckMark" />
                        <div class="font-medium-5 ml-2" >aktualnih novic iz stanovanjske tematike</div>
                    </div>
                    <div class="d-flex justify-content-left mt-2 ml-2">
                        <b-img fluid class="" :src="iconCheckMark" />
                        <div class="font-medium-5 ml-2" >ugodnih storitev za vaš dom</div>
                    </div>
                    <div class="d-flex justify-content-left mt-2 ml-2">
                        <b-img fluid class="" :src="iconCheckMark" />
                        <div class="font-medium-5 ml-2" >spletne tržnice z vašimi oglasi</div>
                    </div>
                    <div class="mt-5 d-flex justify-content-center">
                        <b-button class="button font-medium-2 text-center px-2 py-1 m-0" @click="$router.push('/registracija');" style="max-width: 290px;  background: #72a5D8 !important; color: white!important;" pill>Na registracijo</b-button>
                    </div>
                </div>
            </div>

            <div v-if="lastNews && lastNews.length > 0" class="mt-2 position-relative">
                <div class="text-center mb-2" style="margin-top: 3.5rem;">
                    <div style="font-weight: 700; font-size: 33px; line-height: 41px;">
                        Novice
                    </div>
                </div>
                <b-row v-if="lastNews && lastNews.length >= 3 && highlightNews !== null">
                    <b-col cols="12" md="6">
                        <HomeCardNews :news="lastNews[0]" />
                    </b-col>
                    <b-col cols="12" md="6" class="mt-1 mt-md-0">
                        <HomeCardNews :news="lastNews[1]" />
                    </b-col>
                </b-row>
                <b-row v-else-if="lastNews && lastNews.length >= 3">
                    <b-col cols="12" md="6">
                        <HomeCardNews :news="lastNews[1]" />
                    </b-col>
                    <b-col cols="12" md="6" class="mt-1 mt-md-0">
                        <HomeCardNews :news="lastNews[2]" />
                    </b-col>
                </b-row>
                <!-- <blue-background style="bottom: -6px"/> -->
                <div class="mt-1 d-flex justify-content-end align-items-center">
                    <a class="d-flex justify-content-center align-items-center" @click="$router.push('/novice'); $scrollToTop()">
                        <div class="mr-2 text-secondary" style="font-weight: 700;font-size: 17px;">Vse novice</div>
                        <img style="width: 40px; height: 40px" :src="require('@/assets/svg/circle-arrow-right.svg')"/>
                    </a>
                </div>
            </div>
        </div>
        <div class="realestate w-100" style="background-image: linear-gradient(140deg, #80accd 8%, white 65%); background-repeat: no-repeat; background-size: 100vw 50%; background-position: bottom left">
            <div class="realestate_background">
                <b-row>
                    <b-col lg="5" md="4" class="d-none d-md-block">
                        <div class=" d-flex justify-content-center font-large-2 pt-4" style="color: #72A5D8!important; font-weight: 700;">
                            <div class="text-left">
                                Razmišljate o<br>nakupu ali menjavi<br> nepremičnine
                            </div>
                        </div>
                    </b-col>
                    <b-col lg="7" md="8"/>
                </b-row>
                <b-row style="">
                    <b-col lg="5" md="4" class="d-none d-md-block">
                        <div class=" d-flex justify-content-center font-medium-3 pt-4 mb-5" style="color: white!important; font-weight: 400;">
                            <div class="text-left">
                                Menjava nepremičnine je velik korak, dovolite,<br> da vam ga olajšamo in si oglejte nabor<br> nepremičnin.
                                <div class="d-flex justify-content-left align-content-center mt-2 pb-5">
                                    <b-button class="button font-medium-2 text-center px-3 py-1 m-0" @click="openRealEstatesLink()" style="max-width: 290px;  background: white !important; color: #7da5d7!important; font-weight: 700!important;" pill>Na vse nepremičnine</b-button>
                                </div>
                            </div>
                        </div>
                    </b-col>
                    <b-col lg="7" md="8"/>
                </b-row>
            </div>
        </div>
        <div class="realestate_small" style="position: relative; width: 98vw!important; left: -20px;">
            <div class="pl-2">
                <div class=" d-flex justify-content-start font-large-2 pt-4" style="color: #72A5D8!important; font-weight: 700;">
                    <div class="text-left d-flex">
                        Razmišljate o<br>nakupu ali menjavi<br> nepremičnine
                    </div>
                </div>
                <div class=" d-flex justify-content-start font-medium-3 pt-4 mb-0 pb-0 " style="color: black!important; font-weight: 400;">
                    <div class="text-left">
                        Menjava nepremičnine je velik<br> korak, dovolite, da vam ga<br> olajšamo in si oglejte nabor<br> nepremičnin.
                    </div>
                </div>
            </div>
            <div style=" background-image: linear-gradient(140deg, #9cc5e5 1%, white 55%); background-repeat: no-repeat; background-size: 100% 74%; background-position: bottom; position: relative; top: -5rem">
                <div class="d-flex justify-content-center align-content-center realestate_background_samll" style="padding-top: 26rem; padding-bottom: 4rem;">
                    <b-button class="button font-medium-2 text-center px-2 py-1 m-0" @click="openRealEstatesLink()" style="max-width: 290px;  background: #7da5d7 !important; color: white!important; font-weight: 700!important; z-index: 1;" pill>Na vse nepremičnine</b-button>
                </div>
            </div>
            <div style="height: 5px!important;">
                <div style="background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 70%); height: 260px; top: -260px; width: 100%; position: relative;" />
            </div>
        </div>
        <div class="realestate" style="background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 76%); height: 120px; top: -100px; width: 100%; position: relative;" />
        <div class="mt-0 pt-0 bubles">
            <b-row>
                <b-col lg="5" md="4" class="d-none d-md-block">
                    <div class="text-center font-large-2" style="font-weight: bold; white-space: nowrap;">Pogosta vprašanja</div>
                    <b-img fluid :src="require('@/assets/images/esoseska/esoseska_homepage_3.png')" />
                </b-col>
                <b-col lg="7" md="8">
                    <div class="text-center font-large-2 mb-3 d-block d-md-none" style="font-weight: bold; white-space: nowrap;">Pogosta vprašanja</div>
                    <div class="mt-lg-0 mt-5">
                        <div class="d-flex align-items-center justify-content-end">
                            <!-- <b-img fluid :src="require('@/assets/images/pages/home/buble_1.png')"/> -->
                            <div style="font-weight: bold;" class="centered-1 font-medium-5 text-left d-flex align-items-center justify-content-center pb-5">Zakaj se registrirati v upravniški<br> portal eSoseska?</div>
                        </div>
                        <div class="d-flex align-items-center justify-content-start" style="transform: translateY(-20px)!important;">
                            <!-- <b-img fluid :src="require('@/assets/images/pages/home/buble_4.png')"/> -->
                            <div class="centered-2 font-medium-1 text-left d-flex align-items-center justify-content-center pb-5">Z registracijo dostopate do številnih ugodnosti, kot so<br> upravljanje in spremljanje dokumentacije  upravnika,<br> dostop do storitev, ki vam bodo v pomoč pri skrbi vaših<br> nepremičnin, elektronskega oddajanja dokumentov,<br> prostora za trženje, skratka celotnega pregleda vaše<br> nepremičnine.</div>
                        </div>
                        <div class="d-flex align-items-center justify-content-end" style="transform: translateY(-70px)!important;">
                            <!-- <b-img fluid :src="require('@/assets/images/pages/home/buble_2.png')"/> -->
                            <div style="font-weight: bold;" class="centered-3 font-medium-5 text-left d-flex align-items-center justify-content-center pb-5">Ali je registracija v upravniški<br> portal plačljiva?</div>
                        </div>
                        <div class="d-flex align-items-center justify-content-start" style="transform: translateY(-80px)!important;" >
                            <!-- <b-img fluid :src="require('@/assets/images/pages/home/buble_3.png')"/> -->
                            <div class="centered-4 font-medium-1 text-left d-flex align-items-center justify-content-center pb-1">Ne, registrirate se brezplačno.</div>
                        </div>
                        <div class="mt-0 d-flex justify-content-left">
                            <b-button class="button font-medium-2  text-left px-5 py-1 m-0 d-none d-md-block" style="max-width: 260px;  background: #72a5D8 !important; color: white!important;" pill  @click="$router.push('/pogosta-vprasanja');" >Na vsa vprašanja</b-button>
                        </div>
                        <!-- <div class="mb-5 d-flex justify-content-center">
                            <b-button class="button font-medium-2  text-center px-5 py-1 m-0 d-block d-md-none" style="max-width: 260px;  background: #72a5D8 !important; color: white!important;" pill  @click="$router.push('/pogosta-vprasanja');" >Na vsa vprašanja</b-button>
                        </div> -->
                    </div>
                </b-col>
            </b-row>
        </div>

        <div class="mt-0 pt-0 bubles-small w-100">
            <div class="text-center font-large-2 mb-3 d-block d-md-none" style="font-weight: bold;">Pogosta vprašanja</div>
            <div class="mt-lg-0 mt-5 w-100">
                <div class="d-flex align-items-center justify-content-end">
                    <!-- <b-img fluid :src="require('@/assets/images/pages/home/buble_1.png')"/> -->
                    <div style="font-weight: bold; width: 350px!important; height: 130px!important;" class="centered-1 font-medium-2 text-left d-flex align-items-start pt-2 justify-content-center pb-0">Zakaj se registrirati v upravniški<br> portal eSoseska?</div>
                </div>
                <div class="d-flex align-items-center justify-content-start" style="transform: translateY(-20px)!important;">
                    <!-- <b-img fluid :src="require('@/assets/images/pages/home/buble_4.png')"/> -->
                    <div style="background-size: 100% 210px!important ;width: 350px!important; height: 212px!important; font-size: 0.9rem!important; margin-top: -1rem!important; padding-bottom: 2.8rem!important;" class="centered-2 mb-3 text-left d-flex align-items-center justify-content-center">Z registracijo dostopate do številnih<br> ugodnosti, kot so upravljanje in spremljanje<br> dokumentacije  upravnika, dostop do storitev,<br> ki vam bodo v pomoč pri skrbi vaših<br> nepremičnin, elektronskega oddajanja<br> dokumentov, prostora za trženje, skratka<br> celotnega pregleda vaše nepremičnine.</div>
                </div>
                <div class="d-flex align-items-center justify-content-end" style="transform: translateY(-70px)!important;">
                    <!-- <b-img fluid :src="require('@/assets/images/pages/home/buble_2.png')"/> -->
                    <div style="font-weight: bold; ; width: 350px!important; height: 120px!important;" class="centered-3 pb-3 font-medium-2 text-left d-flex align-items-center justify-content-center pt-0">Ali je registracija v upravniški<br> portal plačljiva?</div>
                </div>
                <div class="d-flex align-items-center justify-content-start" style="transform: translateY(-80px)!important;" >
                    <!-- <b-img fluid :src="require('@/assets/images/pages/home/buble_3.png')"/> -->
                    <div style="margin-top: -1.5rem!important;" class="centered-4 font-medium-1 text-left d-flex align-items-center justify-content-center pb-1">Ne, registrirate se brezplačno.</div>
                </div>
            </div>
        </div>

        <div v-if="services && services.length > 0 && loggedIn && emailConfirmed" class="mt-3 position-relative">
            <div style="font-weight: 700; font-size: 19px; line-height: 23px; color: rgba(0, 0, 0, 0.3);">
                STORITVE
            </div>
            <blue-background style="top: 5rem"/>
            <b-row class="mt-1">
                <b-col cols="12" lg="9">
                    <HomeCardService :service="services[0]" />
                </b-col>
                <b-col cols="12" lg="3" class="mt-1 mt-md-0">
                    <router-link :to="{ name: 'service-list'}">
                        <div class="d-flex flex-column h-100">
                            <div class="to-all d-flex justify-content-center align-items-center flex-column cursor-pointer h-100" style="min-height: 150px" >
                                <img style="width: 70px; height: 70px;" :src="require('@/assets/svg/circle-arrow-right.svg')"/>
                                <h3 class="mt-2 text-secondary">Vse storitve</h3>
                            </div>
                        </div>
                    </router-link>
                </b-col>
            </b-row>
        </div>

        <div v-if="webShopItems && webShopItems.length > 0 && loggedIn && emailConfirmed">
            <div class="text-center mt-3">
                <div style="font-weight: 700; font-size: 33px; line-height: 41px;">
                    Trgovina
                </div>
            </div>
            <swiper :swiper-data="webShopItems" :pagination="true" type="sale"/>
        </div>

        <news-carousel custom-class="mt-2"/>
        
    </b-overlay>
</template>

<script>
    import HomeCardNews from '@/views/Components/Home/HomeCardNews'
    import HomeCardService from '@/views/Components/Home/HomeCardService'
    import { BOverlay, BRow, BCol, BImg, BButton } from 'bootstrap-vue'
    import BlueBackground from '@/views/Components/BlueBackground'
    import newsCarousel from '@/views/Components/NewsCarousel'
    import Swiper from '@/views/Components/Swiper'

    export default {
        components: {
            Swiper,
            BlueBackground,
            BOverlay,
            HomeCardNews,
            BRow,
            BCol,
            newsCarousel,
            HomeCardService,
            BImg,
            BButton
        },
        data() {
            return {
                services: [],
                lastNews: [],
                webShopItems: [],
                highlightNews: null,
                iconCheckMark:require('@/assets/images/icons/icon_check circled_outline_.svg')
            }
        },
        computed:{
            loggedIn() {
                return this.$store.getters['user/loggedIn']
            },
            emailConfirmed() {
                return this.$store.getters['user/emailConfirmed']
            }
        },
        methods: {
            loadData() {
                this.showLoader = true
                try {
                    this.getLastServices()
                    this.getLastNews()
                    this.getWebShopItems()
                    this.getHighlightNews()
                } catch (error) {
                    this.$printError(`Prišlo je do napake pri nalaganju podatkov\n${error.message}`)
                } finally {
                    this.showLoader = false
                }
            },
            getLastServices() {
                if (!this.loggedIn || !this.emailConfirmed) return

                const thisIns = this
                thisIns.$http.get('/api/user/v1/es/services?&page=1&&perPage=1&')
                    .then(res => {
                        this.services = res.data.items ?? []
                    })
                    .catch(error => {
                        thisIns.$printError(`Pri nalaganju storitev je prišlo do napake!\n${error.message}`)
                    })
            },
            getLastNews() {
                const thisIns = this
                thisIns.$http.get('/api/user/v1/es/news/?&page=1&&perPage=3&')
                    .then(res => {
                        this.lastNews = res.data.items ?? []
                    })
                    .catch(error => {
                        thisIns.$printError(`Pri nalaganju novic je prišlo do napake!\n${error.message}`)
                    })
            },
            getWebShopItems() {
                if (!this.loggedIn || !this.emailConfirmed) return
                const thisIns = this
                thisIns.$http.get('/api/user/v1/web_shop/?&page=1&&perPage=8&')
                    .then(res => {
                        this.webShopItems = res.data.items ?? []
                    })
                    .catch(error => {
                        thisIns.$printError(`Pri nalaganju izdelkov je prišlo do napake!\n${error.message}`)
                    })
            },
            getHighlightNews() {
                const thisIns = this
                thisIns.$http.get('/api/user/v1/es/news/highlight')
                    .then(res => {
                        this.highlightNews = res.data.items ?? null
                    })
                    .catch(error => {
                        thisIns.$printError(`Pri nalaganju novic je prišlo do napake!\n${error.message}`)
                    })
            },
            openRealEstatesLink() {
                window.location = 'https://nepremicnine.esoseska.si/'
            }
        },
        mounted() {
            this.loadData()
        }
    }
</script>

<style scoped>
.to-all {
  background-color: #EAF3F9;
}
.bcard-background{
       background: linear-gradient(white 19%, #ebf3f9 24%,  #ebf3f9 85%, white 90%);
       padding-left: 2500px;
       margin-left: -2500px;
       padding-right: 2500px;
       margin-right: -2500px;
    }
    .realestate_background{
        background-image: url(~@/assets/images/pages/home/estate.webp)!important;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: right;
        width: 100%!important;
    }
    .realestate_background_samll{
        background-image: url(~@/assets/images/pages/home/estate_small.webp)!important;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: right;
        width: 100%!important;
    }
</style>
<style>
.more-about-news{
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  border: none;
  border-radius: 30px;
  color: #72a5d8;
  padding: 10px 66px 10px 14px;
  background-color: #FFFFFF;
}

.button {
    font-weight: bold;
    border: none;
        
    }
.button:hover {
    box-shadow: none !important;
}


.centered-1 {
  color: black;
  background-image: url(~@/assets/images/pages/home/buble_1.png)!important;
  background-size: contain;
  background-repeat: no-repeat;
  max-height: 183px; 
  max-width: 532px;
  height: 100vh;
  width: 100vh;
}

.centered-3 {
  color: black;
  background-image: url(~@/assets/images/pages/home/buble_2.png)!important;
  background-size: contain;
  background-repeat: no-repeat;
  max-height: 166px; 
  max-width: 532px;
  height: 100vh;
  width: 100vh;
}

.centered-4 {
        color: black;
        background-image: url(~@/assets/images/pages/home/buble_3.png)!important;
        background-size: contain;
        background-repeat: no-repeat;
        max-height: 112px; 
        max-width: 347px;
        height: 100vh;
        width: 100vh;
}
.centered-2 {
  color: black;
  background-image: url(~@/assets/images/pages/home/buble_4.png)!important;
  background-size: contain;
  background-repeat: no-repeat;
  max-height: 269px; 
  max-width: 562px;
  height: 100vh;
  width: 100vh;
  font-size: 1.1rem !important;
}
@media screen and (max-width: 550px){
    .centered-2 {
        font-size: 0.8rem !important;
        padding-bottom: 7rem!important;
}
}

@media only screen and (min-width: 1061px){
    .realestate {
        display:block!important;
        visibility: visible!important;
    }
    .realestate_small {
        display: none!important;
        visibility: hidden!important;
    }
}

@media only screen and (max-width: 1060px){
    .realestate {
        display: none!important;
        visibility: hidden!important;
    }
    .realestate_small {
        display:block!important;
        visibility: visible!important;
    }
}

@media only screen and (max-width: 610px){
    .bubles-small {
        display:block!important;
        visibility: visible!important;
    }
    .bubles {
        display: none!important;
        visibility: hidden!important;
    }
}

@media only screen and (min-width: 611px){
    .bubles {
        display:block!important;
        visibility: visible!important;
    }
    .bubles-small {
        display: none!important;
        visibility: hidden!important;
    }
}
</style>
