<template>
    <router-link class="more-about-news" :to="{name: 'news', params: {news_id: this.news.id, is_index: false }}" style="padding: 0px;">
        <div v-if="news" class="position-relative pt-1">
            <div class="over-image d-flex flex-column">
                <h1 class="title">{{news.title}}</h1>
                <div class="mt-auto">
                    <router-link class="more-about-news" :to="{name: 'news', params: {news_id: this.news.id, is_index: false }}">
                        > več o tem
                    </router-link>
                </div>
            </div>
            <img class="image" :src="'/api/user/v1/image/' + news.main_photo"/>
        </div>
    </router-link>
</template>

<script>
    export default {
        props:{
            news: {
                type: Object
            }
        }
    }
</script>

<style scoped>
.over-image{
  background: rgba(255, 255, 255, 0.88);
  position: absolute;
  top: 50%;
  bottom: 0;
  left: 0;
  width: 45%;
  padding: 1rem;
}
.more-about-news{
  font-weight: 700;
  font-size: 17px;
  line-height: 21px;
  color: #72A5D8;
}
.title{
  font-weight: 700;
  font-size: 22px;
  line-height: 29px;
  color: black;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  display: -webkit-box;
}
.image {
  width: 100%;
  height: 400px;
  object-fit: cover;
}
@media only screen and (max-width: 500px) {
  .over-image{
    top: 66%;
    width: 100%;
  }
  .title{
    font-size: 18px;
    line-height: 22px;
    -webkit-line-clamp: 2;
  }
  .image {
    height: 300px;
  }
}
</style>